import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Modal,
  Backdrop,
  Fade,
  ThemeProvider, // Added import for ThemeProvider
  createTheme, // Added import for createTheme
} from '@mui/material';
import { styled } from '@mui/system';
import LearnNav from './LearnNav';

const theme = createTheme({
      // ... your theme settings
});

const HoneyImage = styled('img')(({ theme }) => ({
    maxWidth: '100%',
    height: 'auto',
    display: 'block',
    marginBottom: theme.spacing(2),
  }));

// Modal styles
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const RecipeCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%', // Make sure the card stretches to the full height
  }));

// Main Recipes Component
function Recipes() {
  const [open, setOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState({});

  const handleOpen = (recipe) => {
    setSelectedRecipe(recipe);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const recipes = [
    // Example recipe with imageUrl attribute
    {
      id: 1,
      title: "Honey-Glazed Salmon",
      description: "A delightful blend of acacia honey and soy sauce, giving the salmon a sweet and savory glaze.",
      imageUrl: "images/salmon.png",
      ingredients: [
        "4 salmon fillets",
        "2 tablespoons acacia honey",
        "1 tablespoon soy sauce",
        "1 garlic clove, minced",
        "Salt and pepper to taste",
        "Fresh herbs for garnish"
      ],
      directions: [
        "Preheat your oven to 375°F (190°C).",
        "In a small bowl, mix the acacia honey, soy sauce, and minced garlic.",
        "Place salmon fillets on a baking sheet lined with parchment paper. Season with salt and pepper.",
        "Brush the honey mixture over the salmon fillets.",
        "Bake for 15-20 minutes, or until salmon is cooked through and glaze is caramelized.",
        "Garnish with fresh herbs before serving."
      ]
    },
    {
      id: 2,
      title: "Acacia Honey Granola",
      description: "Crunchy, sweet, and nutritious granola clusters coated with acacia honey.",
      imageUrl: "images/granola.png",
      ingredients: [
        "3 cups rolled oats",
        "1 cup mixed nuts, chopped",
        "1/2 cup acacia honey",
        "1/4 cup coconut oil",
        "1/2 cup dried fruits",
        "1 teaspoon vanilla extract",
        "Pinch of salt"
      ],
      directions: [
        "Preheat oven to 300°F (150°C).",
        "In a large bowl, mix oats and nuts.",
        "Warm honey and coconut oil in a saucepan over low heat. Stir in vanilla.",
        "Pour honey mixture over oats and nuts. Mix until well coated.",
        "Spread mixture on a baking sheet. Bake for 30-40 minutes, stirring occasionally.",
        "Let cool. Stir in dried fruits.",
        "Store in an airtight container."
      ]
    },
    {
      id: 3,
      title: "Honey Lavender Ice Cream",
      description: "Creamy ice cream with a delightful hint of lavender and sweetened with natural acacia honey.",
      imageUrl: "images/icecream.png",
      ingredients: [
        "2 cups heavy cream",
        "1 cup whole milk",
        "3/4 cup acacia honey",
        "2 tablespoons dried lavender",
        "6 egg yolks",
        "Pinch of salt"
      ],
      directions: [
        "In a saucepan, combine cream, milk, honey, and lavender. Warm over medium heat until honey dissolves.",
        "In a bowl, whisk egg yolks. Gradually whisk in some of the warm milk mixture.",
        "Return the mixture to the saucepan. Cook over low heat, stirring until it thickens.",
        "Strain the mixture, then chill it thoroughly.",
        "Churn in an ice cream maker according to manufacturer's instructions."
      ]
    },
    {
      id: 4,
      title: "Honey Mustard Dressing",
      description: "A tangy and sweet honey mustard dressing perfect for salads or as a dipping sauce.",
      imageUrl: "images/mustard.png",
      ingredients: [
        "1/4 cup acacia honey",
        "1/4 cup Dijon mustard",
        "1/4 cup apple cider vinegar",
        "1/2 cup olive oil",
        "Salt and pepper to taste"
      ],
      directions: [
        "In a bowl, whisk together honey, mustard, and vinegar.",
        "Gradually add olive oil, whisking until the dressing is emulsified.",
        "Season with salt and pepper.",
        "Store in the refrigerator."
      ]
    },
    {
      id: 5,
      title: "Honey Balsamic Brussel Sprouts",
      description: "Roasted Brussel sprouts glazed with a balsamic and acacia honey reduction.",
      imageUrl: "images/sprouts.png",
      ingredients: [
        "1 lb Brussel sprouts, halved",
        "2 tablespoons olive oil",
        "Salt and pepper to taste",
        "3 tablespoons acacia honey",
        "2 tablespoons balsamic vinegar"
      ],
      directions: [
        "Preheat oven to 400°F (200°C).",
        "Toss Brussel sprouts with olive oil, salt, and pepper. Roast until tender.",
        "In a saucepan, reduce balsamic vinegar and honey over medium heat.",
        "Drizzle the reduction over roasted Brussel sprouts."
      ]
    },
    {
      id: 6,
      title: "Honey Roasted Peanuts",
      description: "Crunchy peanuts roasted with a sweet layer of acacia honey.",
      imageUrl: "images/peanuts.png",
      ingredients: [
        "2 cups unsalted peanuts",
        "1/4 cup acacia honey",
        "2 tablespoons butter",
        "1/2 teaspoon salt",
        "1/4 teaspoon cayenne pepper (optional)"
      ],
      directions: [
        "Preheat oven to 350°F (175°C).",
        "In a saucepan, melt butter and honey. Add peanuts and stir to coat.",
        "Spread peanuts on a baking sheet. Sprinkle with salt and cayenne pepper.",
        "Roast for 15-20 minutes, stirring occasionally.",
        "Let cool before serving."
      ]
    }
  ];
  

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box my={4} sx={{ display: 'flex' }}>
          <Box sx={{ width: '25%', mr: 2, display: 'flex', flexDirection: 'column' }}>
            <HoneyImage src="/images/drip.png" alt="Honey Drip" />
            <LearnNav />
          </Box>
          <Box sx={{ width: '75%' }}>
            <Typography variant="h4" gutterBottom>
              Delightful Acacia Honey Recipes
            </Typography>
            <Grid container spacing={2}>
              {recipes.map((recipe) => (
                <Grid item xs={12} sm={6} key={recipe.id}>
                  <RecipeCard>
                    <CardMedia
                      component="img"
                      image={`${process.env.PUBLIC_URL}/${recipe.imageUrl}`}
                      alt={recipe.title}
                      sx={{ height: 140 }} // Adjust the height as needed
                    />
                    <CardContent sx={{ flexGrow: 1 }}> {/* Allow the content to grow and push the actions to the bottom */}
                      <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'justify' }}>
                        {recipe.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'justify' }}>
                        {recipe.description}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center', paddingBottom: 2 }}> {/* Center the button */}
                      <Button variant="contained" color="primary" onClick={() => handleOpen(recipe)}>
                        View Recipe
                      </Button>
                    </CardActions>
                  </RecipeCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
        <Modal
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
            >
            <Fade in={open}>
                <Box sx={{ ...style, width: 'auto', maxWidth: '90vw', minHeight: '30vh' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                        {selectedRecipe.title}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {selectedRecipe.description}
                        </Typography>
                    </Box>
                    <CardMedia
                        component="img"
                        sx={{ width: 100, height: 100, flexShrink: 0 }}
                        image={`${process.env.PUBLIC_URL}/${selectedRecipe.imageUrl}`}
                        alt={selectedRecipe.title}
                    />
                    </Box>
                    <Box sx={{ overflowY: 'auto' }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                        Ingredients:
                    </Typography>
                    {selectedRecipe.ingredients?.map((ingredient, index) => (
                        <Typography key={index} sx={{ mt: 1 }}>
                        {ingredient}
                        </Typography>
                    ))}
                    <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
                        Directions:
                    </Typography>
                    {selectedRecipe.directions?.map((direction, index) => (
                        <Typography key={index} sx={{ mt: 1 }}>
                        {index + 1}. {direction}
                        </Typography>
                    ))}
                    </Box>
                </Box>
                </Box>
            </Fade>
            </Modal>
      </Container>
    </ThemeProvider>
  );
}

export default Recipes;