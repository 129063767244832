// About.js
import React from 'react';
import { Container, Box, Button, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { Link as RouterLink } from 'react-router-dom';
import RetailerLogos from '../components/RetailerLogos';

// Styled component for the image
const FounderImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
  borderRadius: '4px',
});

function About() {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        {/* Founder Section */}
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <FounderImage src="/images/ibolya.png" alt="Ibolya Green - Founder" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Our Founder
            </Typography>
            <Typography paragraph>
            My name is Ibolya. I grew up in a quiet village in Hungary. It was a healthy lifestyle. We raised the vegetables and fruits we ate. And we enjoyed Hungary's world-famous honey, wild acacia honey. Now, my husband and I live in beautiful California. It was always my dream to introduce this country to the wonderful acacia honey of Hungary. After our girls went off to high school, it was time to follow my dream. Hungary Bees is our family business, dedicated to sourcing and marketing the highest quality acacia honey available. I am proud to say that we have accomplished this goal. Our wild acacia honey is raw, organic, and certified non-GMO, the healthiest honey available anywhere. It is so sweet yet so light it can be used in many drinks and recipes, complementing, not masking, the original flavors. It is lower in calories per serving than other varieties of honey. It is known throughout Europe for its healing and healthy qualities. I hope you will enjoy it as much as our family does.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box my={4}>
        {/* About Honey Section */}
        <Typography variant="h4" gutterBottom>
          About Hungary Bees Honey
        </Typography>
        <Typography paragraph>
        Hungarian acacia honey has long been known throughout Europe as nature's finest honey. Consistently a winner at international contests and industry expositions as the world's best-tasting honey, Hungarian acacia honey is unique. Ibolya Green founded Hungary Bees to source and introduce to the US the highest quality Hungarian acacia honey. Because of its outstanding quality and value, Hungary Bees Wild Acacia Honey is now a well-established, trusted brand name across California and will soon be available in the finest gourmet and health food markets across the nation.
        </Typography>
      </Box>

      <Box mt={5} display="flex" justifyContent="flex-end" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Visit our Store
        </Typography>
        <Button variant="contained" color="primary" component={RouterLink} to="/shop" sx={{ marginLeft: 'auto' }}>
          Shop Now
        </Button>
      </Box>
      <RetailerLogos /> 

    </Container>
  );
}

export default About;
