import React from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  createTheme,
  ThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CssBaseline,
} from '@mui/material';
import { styled } from '@mui/system';
import LearnNav from './LearnNav';

// Enhanced theme instance with additional customizations
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
      light: '#7986cb',
      dark: '#303f9f',
      contrastText: '#ffffff',
    },
    background: {
      default: '#f6f6f6',
      paper: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h5: {
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
        },
      },
    },
  },
});

const BenefitImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  display: 'block',
  marginBottom: theme.spacing(2),
}));

const LearnMoreButton = styled(Button)(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Callout = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

// Comparison table with better styling
function BenefitsComparisonTable() {
  const comparisonData = [
    {
      attribute: 'Glycemic Index',
      acaciaHoney: 'Low',
      otherSweeteners: 'Varies (often high)',
      benefit: 'Better for blood sugar control',
    },
    {
      attribute: 'All-Natural',
      acaciaHoney: 'Yes',
      otherSweeteners: 'No (many are processed)',
      benefit: 'Free from artificial additives',
    },
    {
      attribute: 'Antioxidants',
      acaciaHoney: 'High',
      otherSweeteners: 'Low to none',
      benefit: 'Supports overall health',
    },
    {
      attribute: 'Flavor',
      acaciaHoney: 'Rich & delicate',
      otherSweeteners: 'Often overly sweet',
      benefit: 'Enhances food without overpowering',
    },
    {
      attribute: 'Antibacterial Properties',
      acaciaHoney: 'Natural antibiotic',
      otherSweeteners: 'None',
      benefit: 'May improve oral and digestive health',
    },
    {
      attribute: 'Shelf Life',
      acaciaHoney: 'Indefinite',
      otherSweeteners: 'Limited',
      benefit: 'No need for preservatives or refrigeration',
    },
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="benefits comparison table">
        <TableHead>
          <TableRow>
            <TableCell>Attribute</TableCell>
            <TableCell align="center">Acacia Honey</TableCell>
            <TableCell align="center">Alternative Sweeteners</TableCell>
            <TableCell align="center">Benefit of Acacia Honey</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comparisonData.map((row) => (
            <TableRow
              key={row.attribute}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.attribute}
              </TableCell>
              <TableCell align="center">{row.acaciaHoney}</TableCell>
              <TableCell align="center">{row.otherSweeteners}</TableCell>
              <TableCell align="center">{row.benefit}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// The main Benefits component
function Benefits({ checkout, onCartUpdate }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box my={4}>
          <Paper elevation={3} sx={{ padding: theme.spacing(4), margin: theme.spacing(2, 0) }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <BenefitImage src="/images/blossom.jpg" alt="Honey Benefits" />
                <LearnMoreButton 
                  variant="contained" 
                  color="primary" 
                  component="a" 
                  href="#buy-button"
                >
                  View Our Products
                </LearnMoreButton>
                <LearnNav />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h5" gutterBottom sx={{ fontSize: 'clamp(16px, 5vw, 24px)' }}>
                  Our Wild Acacia Honey is a National Treasure
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="A Forest Treasure, Unveiled"
                      secondary={
                        "Embark on a sensory journey with our Pure Acacia Honey, a golden elixir born from the lush acacia forests that grace over one-fifth of Hungary's verdant landscape. Each spoonful is a testament to our commitment to purity and a tribute to the land's bountiful heritage, bringing to your palate the undiluted essence of Hungary's most cherished nectar."
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="The Harmony of Nature in Every Drop"
                      secondary={
                        "Savor the symphony of flavors with our Harmonic Taste Acacia Honey, where the sweet whispers of acacia blossoms are lovingly captured in our meticulous harvest. This isn't just honey—it's the flavor of tranquility itself, meticulously bottled to offer you a taste of the serene Hungarian countryside, untouched by time."
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Wellness from the Hive to Your Home"
                      secondary={
                        "Elevate your well-being with the Health Benefits of our Acacia Honey, a natural remedy steeped in tradition and backed by modern science. Revered for its antiseptic and healing properties, our honey is not only a delight to your taste buds but also a guardian to your health, promising you nature's best defense in its purest form."
                      }
                    />
                  </ListItem>
                </List>
                <Callout>
                  Did You Know? The lighter the color of acacia honey, the higher the purity. It's not just honey, it's a work of art.
                </Callout>
              </Grid>
            </Grid>
            <BenefitsComparisonTable /> 
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Benefits;
