// shopify.js
import Client from 'shopify-buy';

// Set up a client to use the Shopify Buy SDK
const client = Client.buildClient({
  domain: process.env.REACT_APP_SHOPIFY_DOMAIN, // Your Shopify domain
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN, // Your Shopify Storefront access token
});

export default client;
