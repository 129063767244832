import React from 'react';
import { Container, Grid, Box, Typography, Chip } from '@mui/material';
import BuyButton from '../components/BuyButton';
import RetailerLogos from '../components/RetailerLogos';

function HomePage({ checkout, onCartUpdate }) {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Grid container spacing={2}>
          {/* Narrative Section - 3/4 width */}
          <Grid item xs={12} md={9}>
            <Typography variant="h5" gutterBottom>
              Welcome to Hungary Bees
            </Typography>
            <Typography variant="body1" paragraph>
            For over a decade, Hungary Bees has been a staple in brick-and-mortar high-end grocers, offering the finest quality honey to gourmet enthusiasts. 
            </Typography>
            <Typography variant="body1" paragraph>
            Experience the purity and natural sweetness of our carefully harvested honey, brought from our hives to your home with just a few clicks. All transactions are secure and seamlessly powered by Shopify, ensuring a smooth and secure shopping experience.
            </Typography>
          </Grid>

          {/* Free Express Shipping Badge */}
          <Grid item xs={12} md={3} display="flex" justifyContent="center" alignItems="center">
            <Chip label="Free Express Shipping on Orders $38+" color="primary" />
          </Grid>
        </Grid>

        {/* BuyButton Component */}
        <Box mt={2} display="flex" justifyContent="center">
        <BuyButton 
            checkout={checkout} 
            onCartUpdate={onCartUpdate} 
            sourcePage="Home"
          />
        </Box>
        <RetailerLogos /> 
      </Box>
    </Container>
  );
}

export default HomePage;
