// RecipesPage.js
import React from 'react';
import { Container, Box } from '@mui/material';
import NaturalSweetener from '../components/NaturalSweetener';
import BuyButton from '../components/BuyButton';

function NaturalSweetenerPage({ checkout, onCartUpdate }) {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <NaturalSweetener/>
        <Box id="buy-button" mt={2}>
        <BuyButton 
            checkout={checkout} 
            onCartUpdate={onCartUpdate} 
            sourcePage="NaturalSweetener"
            />
        </Box>
      </Box>
    </Container>
  );
}

export default NaturalSweetenerPage;
