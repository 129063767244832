import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  CircularProgress,
  Grid,
  Paper
} from '@mui/material';

const NewsletterSubscription = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: ''
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await fetch('https://us-central1-hungarybees.cloudfunctions.net/subscribeNewsletter', {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setLoading(false);

      if (response.ok) {
        setSnackbarMessage('Successfully subscribed to newsletter!');
        setFormData({ firstName: '', lastName: '', email: '' }); // Clear the form fields on successful subscription
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || 'An error occurred. Please try again.');
      }
    } catch (error) {
      setLoading(false);
      setSnackbarMessage('Network error. Please try again.');
      console.error('There has been a problem with your fetch operation:', error);
    }

    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Paper elevation={2} sx={{ p: 3, mt: 4, backgroundColor: '#f7f7f7' }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" gutterBottom>
            Stay Updated
          </Typography>
          <Typography variant="body1">
            Subscribe to our newsletter and stay updated on the latest products and special offers!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: { xs: 2, sm: 0 } }}
          >
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              type="text"
              value={formData.firstName}
              onChange={handleChange}
              required
              disabled={loading}
              variant="outlined"
            />
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              type="text"
              value={formData.lastName}
              onChange={handleChange}
              required
              disabled={loading}
              variant="outlined"
              sx={{ mt: 2 }}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              disabled={loading}
              variant="outlined"
              sx={{ mt: 2 }}
            />
            <Box mt={2} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading || !formData.email || !formData.firstName || !formData.lastName}
                endIcon={loading ? <CircularProgress size={24} /> : null}
              >
                {loading ? 'Subscribing...' : 'Subscribe'}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Paper>
  );
};

export default NewsletterSubscription;
