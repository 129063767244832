import React from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // For bullet points
import { styled } from '@mui/system';
import LearnNav from './LearnNav';

// Create a theme instance for visual appeal
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffcc00', // A honey-like color
      contrastText: '#ffffff',
    },
    // Additional theme options can go here
  },
  typography: {
    h5: {
      fontWeight: 'bold',
    },
    body1: {
      lineHeight: '1.5',
    },
  },
});

// Styled components for enhanced visual styling
const SweetenerImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
  display: 'block',
  marginBottom: '16px',
});

const LearnMoreButton = styled(Button)(({ theme }) => ({
  display: 'block',
  marginTop: '16px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Highlight = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

// The NaturalSweetener component
function NaturalSweetener() {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        <Box my={4}>
          <Paper elevation={3} sx={{ padding: '32px', borderRadius: '8px' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <SweetenerImage src="/images/uses.png" alt="Natural Sweetener Benefits" />
                <LearnMoreButton variant="contained" component="a" href="#buy-button">
                  Discover Our Products
                </LearnMoreButton>
                <LearnNav />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant="h5" gutterBottom sx={{ fontSize: 'clamp(18px, 5vw, 28px)' }}>
                  <Highlight>Discover the Sweetness:</Highlight> Why Honey is the Superior Natural Sweetener
                </Typography>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Low Glycemic Index" secondary="Gentle on your blood sugar, perfect for maintaining a balanced diet." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Rich in Antioxidants" secondary="A boost for your health with every spoonful." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Natural Antibacterial Properties" secondary="Not just sweet, but beneficial for your well-being." />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <CheckCircleOutlineIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Versatile Culinary Use" secondary="From sweetening drinks to baking, honey is your go-to natural sweetener." />
                  </ListItem>
                </List>
                <Typography paragraph>
                  Say goodbye to artificial sweeteners and embrace the harmonious sweetness of acacia honey. Its subtle, aromatic flavors are guaranteed to delight your palate and elevate your dishes. 
                </Typography>
                <LearnMoreButton variant="contained" component="a" href="/shop" sx={{ mt: 3 }}>
                  Shop Now
                </LearnMoreButton>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default NaturalSweetener;
