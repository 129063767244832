import React, { useState } from 'react';
import { Container, Box, Typography, Button, Grid, Collapse } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Home() {
  const [readMoreProducts, setReadMoreProducts] = useState(false);
  const [readMoreAbout, setReadMoreAbout] = useState(false);
  const [readMoreBenefits, setReadMoreBenefits] = useState(false);

  // Handler for tracking the button click
  const trackButtonClick = (buttonName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'shopButtonClick',
      'buttonName': buttonName
    });
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        {/* Responsive Title */}
        <Typography variant="h2" gutterBottom sx={{ fontSize: '2.5rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
          Europe’s Most Prized Honey - Delivered To Your Door!
        </Typography>

        {/* Text and Image Section */}
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} md={8}>
            <Typography variant="h5" paragraph>
              Acacia Honey is a national treasure in Hungary - the only place you can find pristine acacia forests. These trees, whose white blossoms for a few short weeks each spring, yield our unique, light, sweet honey. Hungary Bees Wild Acacia Honey is organic, GMO-free, and raw to retain all of its natural nutritional value and health benefits. There are no hive antibiotics or less expensive honey blends, no added sugars or water... just pure Acacia Honey. Enjoy our healthy, great-tasting honey.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              sx={{
                height: 'auto',
                width: '100%',
                maxWidth: '100%',
              }}
              alt="Acacia Trees"
              src="/images/acacia.jpg"
            />
            
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={4}>
        {/* Our Products Section */}
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4" gutterBottom>
              Our Products
            </Typography>
            <Collapse in={readMoreProducts} collapsedSize={75}>
              <Typography paragraph>
                Hungary Bees prides itself on partnering with the finest raw acacia honey producers in Hungary, ensuring every jar captures the essence of the region's pristine natural landscapes. Our selection caters to diverse preferences, offering an array of sizes suitable for any occasion or use. Committed to convenience and customer satisfaction, Hungary Bees provides complimentary delivery on orders over $38, directly bringing the sweetness of Hungary's treasured honey to your doorstep.
              </Typography>
            </Collapse>
            <Button onClick={() => setReadMoreProducts(!readMoreProducts)} sx={{ alignSelf: 'start', mt: 2 }}>
              {readMoreProducts ? 'Read Less' : 'Read More'}
            </Button>
            <Button variant="contained" color="primary" component={RouterLink} to="/shop" sx={{ mt: 'auto' }}>
              Shop Now
            </Button>
          </Box>
        </Grid>

        {/* About Hungary Bees Section */}
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4" gutterBottom>
              About Hungary Bees
            </Typography>
            <Collapse in={readMoreAbout} collapsedSize={75}>
              <Typography paragraph>
                Ibolya, our founder, hails from a Hungarian village renowned for its wild acacia honey. Settling in California, she launched Hungary Bees to offer this raw, organic, and non-GMO delicacy to the U.S. Known for its light, sweet flavor, our honey is celebrated across Europe for its health benefits. As Hungary Bees grows, it remains a trusted, award-winning name in fine honey.
              </Typography>
            </Collapse>
            <Button onClick={() => setReadMoreAbout(!readMoreAbout)} sx={{ alignSelf: 'start', mt: 2 }}>
              {readMoreAbout ? 'Read Less' : 'Read More'}
            </Button>
            <Button variant="outlined" color="primary" component={RouterLink} to="/about" sx={{ mt: 'auto' }}>
              Learn More
            </Button>
          </Box>
        </Grid>

        {/* Health Benefits Section */}
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column">
            <Typography variant="h4" gutterBottom>
              Health Benefits
            </Typography>
            <Collapse in={readMoreBenefits} collapsedSize={75}>
              <Typography paragraph>
                Hungarian acacia honey stands out as an exquisite natural sweetener, offering a mild, clean taste that complements without overpowering. A staple in healthy kitchens, it enriches recipes with a smooth sweetness derived from Hungary's lush acacia groves. Hungary Bees' honey is the essence of purity, providing a nutritious alternative to refined sugars while also contributing to a balanced diet with its rich vitamins and minerals. Ideal for sweetening tea, drizzling on yogurt, or as a key ingredient in baking, it's a versatile addition to any meal, celebrated for its flavor and health-promoting qualities.
              </Typography>
            </Collapse>
            <Button onClick={() => setReadMoreBenefits(!readMoreBenefits)} sx={{ alignSelf: 'start', mt: 2 }}>
              {readMoreBenefits ? 'Read Less' : 'Read More'}
            </Button>
            <Button variant="outlined" color="primary" component={RouterLink} to="/learn/health" sx={{ mt: 'auto' }}>
              Discover
            </Button>
          </Box>
        </Grid>
      </Grid>


    </Container>
  );
}

export default Home;