import React, { useState, useEffect } from 'react';
import client from '../shopify';
import {
  Grid,
  Card,
  Modal,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Container
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';


const formatPrice = (amount, currencyCode = 'USD') => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(amount);
};

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const cutOffPoint = 100; // Number of characters to show before 'Read More'

  return (
    <Typography variant="body2" color="textSecondary" component="p">
      {isReadMore ? text.slice(0, cutOffPoint) : text}
      <Button color="primary" onClick={toggleReadMore}>
        {isReadMore ? 'Read More' : 'Show Less'}
      </Button>
    </Typography>
  );
};

const ExpandedProductModal = ({ open, product, onClose, onAddToCart }) => {
  // Style for the price badge
  const priceBadgeStyle = {
    position: 'absolute',
    top: 16,
    right: 16,
    backgroundColor: 'gold',
    color: 'black',
    padding: '8px 16px',
    borderRadius: '20px',
    fontWeight: 'bold',
    fontSize: '1rem',
    zIndex: 1000, // Ensure it's above the other content
  };

  // Adjust these styles to ensure the image fits well in the modal
  const imageStyle = {
    width: '100%',
    maxHeight: '400px',
    objectFit: 'contain'
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute', top: '50%', left: '50%',
        transform: 'translate(-50%, -50%)', bgcolor: 'background.paper',
        boxShadow: 24, p: 4, outline: 'none', width: 'auto',
        maxHeight: '80vh', overflowY: 'auto', pt: '64px' // Added padding top to account for the price badge
      }}>
        <Typography variant="h5" component="h2" sx={{
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          mb: 2, // margin-bottom for spacing
        }}>
          {product.title}
        </Typography>
        <Carousel autoPlay={false}>
          {product.images.map((image, index) => (
            <img key={index} src={image.src} alt={product.title} style={imageStyle} />
          ))}
        </Carousel>
        <Typography variant="body2" color="textSecondary" sx={{ my: 2 }}>{product.description}</Typography>
        {/* Price Badge */}
        <Box style={priceBadgeStyle}>
          {formatPrice(product.variants[0].priceV2.amount, product.variants[0].priceV2.currencyCode)}
        </Box>
        {/* Add to Cart Button, now moved inside the main container for proper spacing */}
        <Box sx={{ mt: 2 }}> {/* Margin top to give space from the description */}
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth
            onClick={() => onAddToCart(product.variants[0].id)}
            sx={{ py: 2 }} // Padding for the button height
          >
            Add to Cart
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};



const ProductCard = ({ product, onAddToCart }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleBuyNowClick = (event, variantId) => {
    event.stopPropagation(); // This stops the event from propagating to the parent card
    onAddToCart(variantId);
  };

  const titleStyle = {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: '3.6em', // Adjust based on line-height and font-size to fit exactly two lines.
    maxHeight: '3.6em', // Same as minHeight to enforce two lines.
  };

  return (
    <>
        <Typography gutterBottom variant="h6" component="h2" style={titleStyle}>
          {product.title}
        </Typography>
          <Card style={{ cursor: 'pointer' }} onClick={handleOpenModal}>
        <Carousel autoPlay={false}>
          {product.images.map((image, index) => (
            <CardMedia
              key={index}
              component="img"
              height="175" // Adjust height as needed
              image={image.src}
              alt={product.title}
            />
          ))}
        </Carousel>
        <CardContent>
          <ReadMore>{product.description}</ReadMore>
        </CardContent>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
          <Typography variant="body2" color="textSecondary" component="p" style={{ flexGrow: 1 }}>
            {formatPrice(product.variants[0].priceV2.amount, product.variants[0].priceV2.currencyCode)}
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={(event) => handleBuyNowClick(event, product.variants[0].id)}
            style={{ width: 'auto', flexGrow: 0, marginLeft: 'auto' }} // Adjust width and margin to make the button more prominent
          >
            Buy Now
          </Button>
        </CardActions>

        </Card>
      {modalOpen && (
        <ExpandedProductModal
          open={modalOpen}
          product={product}
          onClose={handleCloseModal}
          onAddToCart={onAddToCart}
        />
      )}
    </>
  );
};

const BuyButton = ({ checkout, onCartUpdate, sourcePage }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    client.product.fetchAll().then((fetchedProducts) => {
      setProducts(fetchedProducts || []);
      setLoading(false);
    }).catch((error) => {
      console.error('Failed to fetch products:', error);
      setLoading(false);
    });
  }, []);

  const handleAddToCart = (variantId) => {
    const lineItemsToAdd = [{ variantId, quantity: 1 }];
    if (checkout) {
      client.checkout.addLineItems(checkout.id, lineItemsToAdd).then((updatedCheckout) => {
        onCartUpdate(updatedCheckout);
      }).catch((error) => {
        console.error('Failed to add item to cart:', error);
      });
    } else {
      client.checkout.create().then((newCheckout) => {
        client.checkout.addLineItems(newCheckout.id, lineItemsToAdd).then(onCartUpdate).catch((error) => {
          console.error('Failed to add item to cart:', error);
        });
      }).catch((error) => {
        console.error('Failed to create checkout:', error);
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'addToCart',
        'productID': variantId,
        'quantity': 1,
        'sourcePage': sourcePage  // Correctly reference the sourcePage
      });
    };
  };

  if (loading) return <div>Loading products...</div>;

  return (
    <Container>
      <Box my={4}>
      <Grid container spacing={4} justifyContent="center">
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
            <ProductCard product={product} onAddToCart={handleAddToCart} />
          </Grid>
        ))}
      </Grid>



      </Box>
    </Container>
  );
};

export default BuyButton;
