import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import client from './shopify';
import './App.css';
import Home from './components/Home';
import About from './components/About';
import NewsletterSubscription from './components/NewsletterSubscription';
import Header from './components/Header';
import Footer from './components/Footer';
import BuyButton from './components/BuyButton';
import BenefitsPage from './pages/BenefitsPage';
import RecipesPage from './pages/RecipesPage';
import Store from './pages/StorePage';
import NaturalSweetenerPage from './pages/NaturalSweetenerPage';
import { Fab, Badge, Container } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-TKJ2JRGL'
};

TagManager.initialize(tagManagerArgs);

function App() {
  const [checkout, setCheckout] = useState(null);
  const [isCartVisible, setIsCartVisible] = useState(false);

  // Update line item in cart (quantity)
  const updateLineItem = (checkoutId, lineItemId, quantity) => {
    if (quantity < 0) {
      console.error('Quantity cannot be negative');
      return Promise.reject('Quantity cannot be negative');
    }
    return client.checkout.updateLineItems(checkoutId, [{ id: lineItemId, quantity }])
      .then(updatedCheckout => {
        onCartUpdate(updatedCheckout);
        return updatedCheckout;
      });
  };

  // Remove line item from cart
  const removeLineItem = (checkoutId, lineItemId) => {
    return client.checkout.removeLineItems(checkoutId, [lineItemId])
      .then(updatedCheckout => {
        onCartUpdate(updatedCheckout);
        return updatedCheckout;
      });
  };

  // Define the function that will update the checkout state
  const onCartUpdate = (updatedCheckout) => {
    setCheckout(updatedCheckout);
    localStorage.setItem('checkoutId', updatedCheckout.id);
    setIsCartVisible(true); // Automatically open the cart modal after update
  
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'cartUpdate',
      'checkoutId': updatedCheckout.id,
      'cartTotal': updatedCheckout.lineItemsSubtotalPrice.amount
    });
  };
  

// Hook to track page views (route changes) in SPA
const TrackPageView = () => {
  const location = useLocation();
  const prevLocationRef = useRef();

  useEffect(() => {
    const prevLocation = prevLocationRef.current;
    
    // Only trigger pageView if the pathname has actually changed
    if (prevLocation && prevLocation.pathname !== location.pathname) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'pageView',
        'url': window.location.href,
        'path': location.pathname,
        'title': document.title
      });
    }
    
    // Update the previous location regardless of whether it's a route change or state change
    prevLocationRef.current = location;
  }, [location]);

  return null;
};



  // Fetch the existing checkout from local storage or create a new one
  useEffect(() => {
    const checkoutId = localStorage.getItem('checkoutId');
    if (checkoutId) {
      client.checkout.fetch(checkoutId).then((existingCheckout) => {
        setCheckout(existingCheckout);
      }).catch((error) => {
        console.error('Failed to fetch checkout:', error);
      });
    } else {
      client.checkout.create().then((newCheckout) => {
        localStorage.setItem('checkoutId', newCheckout.id);
        setCheckout(newCheckout);
      }).catch((error) => {
        console.error('Failed to create checkout:', error);
      });
    }
  }, []);

  const toggleCartVisibility = () => {
    setIsCartVisible(!isCartVisible);
  };

  const totalQuantity = checkout ? checkout.lineItems.reduce((total, item) => total + item.quantity, 0) : 0;

  return (
    <Router>
      <TrackPageView />
      <Header 
        checkout={checkout} 
        toggleCartVisibility={toggleCartVisibility}
        isCartVisible={isCartVisible} 
        updateLineItem={updateLineItem}
        removeLineItem={removeLineItem}
      />
      <Fab 
        color="primary" 
        style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          zIndex: 1000,
        }} 
        onClick={toggleCartVisibility}
      >
        <Badge badgeContent={totalQuantity} color="secondary">
          <ShoppingCartIcon />
        </Badge>
      </Fab>
      <Container sx={{ flexGrow: 1, pt: 2, pb: 2, px: 3 }}>
        <Routes>
          <Route path="/" element={<Home />} />
        <Route path="/shop" element={<Store checkout={checkout} onCartUpdate={onCartUpdate} />} />
        <Route path="/about" element={<About checkout={checkout} onCartUpdate={onCartUpdate} />} />
        <Route path="/contact" element={<NewsletterSubscription checkout={checkout} onCartUpdate={onCartUpdate} />} />
        <Route path="/learn/health" element={<BenefitsPage checkout={checkout} onCartUpdate={onCartUpdate} />} />
        <Route path="/learn/recipes" element={<RecipesPage checkout={checkout} onCartUpdate={onCartUpdate} />} />
        <Route path="/learn/sweetener" element={<NaturalSweetenerPage checkout={checkout} onCartUpdate={onCartUpdate} />} />
      </Routes>
      </Container>
      <Footer sx={{ mt: 'auto' }} />
    </Router>
  );
}

export default App;
