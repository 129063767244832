import React from 'react';
import { Button, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const LearnNav = () => {
  // Define your routes and their display names
  const routes = [
    { path: '/learn/health', label: 'Benefits' },
    { path: '/learn/sweetener', label: 'Natural Sweetener' },
    { path: '/learn/recipes', label: 'Recipes' },
    // Add more routes as needed
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}> 
      {routes.map(route => (
        <Button 
          key={route.path} 
          component={RouterLink} 
          to={route.path} 
          variant="outlined"  // Changed to 'outlined' for a less predominant look
          color="primary"     // Adjust the color if needed
          size="small"        // You can change the size to 'small' for less predominance
          sx={{ 
            justifyContent: 'flex-start', 
            textTransform: 'none', // Removes uppercase styling
            fontWeight: 'normal',  // Normal font weight instead of bold
          }}
        >
          {route.label}
        </Button>
      ))}
    </Box>
  );
};

export default LearnNav;