import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Box,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
  Link as MuiLink
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCart from './ShoppingCart';
import { Link } from 'react-router-dom';
import { css, keyframes } from '@emotion/react';

// Define the keyframes for the shake animation
const shake = keyframes`
  0% { transform: translateX(0px); }
  10% { transform: translateX(-10px); }
  20% { transform: translateX(10px); }
  30% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  50% { transform: translateX(-10px); }
  60% { transform: translateX(10px); }
  70% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  90% { transform: translateX(-10px); }
  100% { transform: translateX(0px); }
`;

// The animation css to be applied when the cart should shake
const shakeAnimation = css`
  animation: ${shake} 0.5s ease-in-out;
`;

function Header({ checkout, toggleCartVisibility, isCartVisible, updateLineItem, removeLineItem }) {
  const [animateCart, setAnimateCart] = useState(false);
  // Calculate totalQuantity here, outside of useEffect to make it available throughout the component
  const totalQuantity = checkout ? checkout.lineItems.reduce((total, item) => total + item.quantity, 0) : 0;
  const [learnAnchorEl, setlearnAnchorEl] = useState(null);
  const open = Boolean(learnAnchorEl);
  
  // Event handlers
  const handleMouseOver = (event) => {
    setlearnAnchorEl(event.currentTarget);
  };

  const handleMouseOut = () => {
    setlearnAnchorEl(null);
  };

  // Effect to trigger the shake animation
  useEffect(() => {
    if (totalQuantity > 0) {
      setAnimateCart(true);
      const timer = setTimeout(() => {
        setAnimateCart(false);
      }, 500); // Duration of the animation
      return () => clearTimeout(timer);
    }
  }, [totalQuantity]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar position="static" sx={{
      backgroundImage: `url(/images/forest.jpg)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center 65%',
      backgroundBlendMode: 'lighten',
      backgroundColor: 'rgba(255, 255, 255, 0.65)',
      padding: theme.spacing(2, 0), // Add padding to the top and bottom of the AppBar
    }}>
      <Toolbar sx={{ 
        maxWidth: '1280px', 
        margin: '0 auto', 
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end', // Align items to the bottom
        justifyContent: 'space-between',
        padding: theme.spacing(0, 2), // Add padding to the left and right of the Toolbar
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}> 
          <Link to="/" style={{ textDecoration: 'none' }}>
            <img src="/images/logo1.png" alt="Hungary Bees Logo" style={{ maxHeight: '150px', width: 'auto' }} />
          </Link>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
          <nav>
            <ul style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
              <li style={{ margin: '0 10px' }}><Link to="/">Home</Link></li>
              <li style={{ margin: '0 10px' }}><Link to="/shop">Shop</Link></li>
              <li style={{ margin: '0 10px' }}><Link to="/about">About</Link></li>
              <li style={{ /* styles */ }}>
              <MuiLink
                component={Link}
                to="/learn"
                onMouseOver={handleMouseOver}
                sx={{ /* styles */ }}
              >
                Learn
              </MuiLink>
              <Menu
                id="learn-menu"
                anchorEl={learnAnchorEl}
                open={open}
                onClose={handleMouseOut}
                MenuListProps={{ onMouseLeave: handleMouseOut }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem component={Link} to="/learn/health">Health Benefits</MenuItem>
                <MenuItem component={Link} to="/learn/sweetener">Natural Sweetener</MenuItem>
                <MenuItem component={Link} to="/learn/recipes">Recipes</MenuItem>
              </Menu>
            </li>
              <li style={{ margin: '0 10px' }}><Link to="/contact">Contact</Link></li>
              </ul>
          </nav>
          <IconButton onClick={toggleCartVisibility} color="inherit" sx={{ 
            transform: animateCart ? 'scale(1.2)' : 'scale(1)',
            transition: 'transform 0.3s ease-in-out',
          }}>
            <Badge badgeContent={totalQuantity} color="secondary">
              <ShoppingCartIcon css={animateCart ? shakeAnimation : null} />
            </Badge>
          </IconButton>
        </Box>
      </Toolbar>
      <ShoppingCart 
        checkout={checkout} 
        isVisible={isCartVisible} 
        toggleCart={toggleCartVisibility}
        updateLineItem={updateLineItem} 
        removeLineItem={removeLineItem} 
      />
    </AppBar>
  );
}

export default Header;
