import React from 'react';
import { Box, Container, Typography, Link, Grid } from '@mui/material';

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3} justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary" align="center">
              Connect with us:
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Link href="https://www.facebook.com/hungarybees/" color="inherit" sx={{ mx: 1 }}>
                Facebook
              </Link>
              |
              <Link href="https://www.instagram.com/hungry_bees/" color="inherit" sx={{ mx: 1 }}>
                Instagram
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="textSecondary" align="center">
              &copy; {new Date().getFullYear()} Hungary Bees
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
