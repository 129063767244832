// RetailerLogos.js
import React from 'react';
import Slider from 'react-slick';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// Import slick carousel CSS files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RetailerLogos = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 768, // Adjusts for devices below 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Placeholder array for retailer logos
  // Replace with actual logo image paths
  const retailerLogos = [
    "images/whole_foods.png",
    "images/gelsons.png",
    "images/lazy_acres.png",
    "images/lassens.png",
    "images/bristol_farms.png",
    "images/mothers.png",
    "images/erewhon.png",
    "images/jensens.png",
    "images/draegers.jpg",
  ];

  return (
    <Box mt={4}>
      <Typography variant="h6" textAlign="center" gutterBottom>
        Find us in these major retailers:
      </Typography>
      <Slider {...settings}>
        {retailerLogos.map((logo, index) => (
          <Box key={index} px={1} display="flex" justifyContent="center">
            <img src={logo} alt={`Retailer ${index + 1}`} style={{ width: 'auto', height: 100 }} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default RetailerLogos;
