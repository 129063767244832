import React from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

const ShoppingCart = ({ checkout, isVisible, toggleCart, updateLineItem, removeLineItem }) => {
  const formatPrice = (amount, currencyCode) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(amount);
  };

  const handleCheckout = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'initiateCheckout',
          'checkoutId': checkout.id
        });
        window.location.href = checkout.webUrl;
    };

    const handleQuantityChange = (lineItemId, newQuantity) => {
        updateLineItem(checkout.id, lineItemId, newQuantity)
            .then(() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'updateQuantity',
                  'lineItemId': lineItemId,
                  'newQuantity': newQuantity
                });
            })
            .catch((error) => {
                console.error('Failed to update line item:', error);
            });
    };

    const handleRemoveItem = (lineItemId) => {
        removeLineItem(checkout.id, lineItemId)
            .then(() => {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  'event': 'removeFromCart',
                  'lineItemId': lineItemId
                });
            })
            .catch((error) => {
                console.error('Failed to remove line item:', error);
            });
    };

    return (
        <Drawer anchor="right" open={isVisible} onClose={toggleCart}>
          <Box sx={{ width: 360, p: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" component="div">
                Shopping Cart
              </Typography>
              <IconButton onClick={toggleCart}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ my: 1 }} />
            <List>
              {checkout && checkout.lineItems.map((item) => (
                <ListItem key={item.id} divider>
                  <Card variant="outlined" sx={{ width: '100%' }}>
                    <CardContent>
                      <Typography variant="body1" component="div">
                        {item.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {`Quantity: ${item.quantity} - ${formatPrice(item.variant.priceV2.amount, item.variant.priceV2.currencyCode)}`}
                      </Typography>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton onClick={() => handleQuantityChange(item.id, Math.max(1, item.quantity - 1))} color="primary">
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                        <IconButton onClick={() => handleQuantityChange(item.id, item.quantity + 1)} color="primary">
                          <AddCircleOutlineIcon />
                        </IconButton>
                        <IconButton onClick={() => handleRemoveItem(item.id)} color="error">
                          <RemoveShoppingCartIcon />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </Card>
                </ListItem>
              ))}
            </List>
            <Divider sx={{ my: 2 }} />
            <Box textAlign="center" mb={2}>
              <Button variant="contained" color="primary" onClick={handleCheckout} startIcon={<img src="/images/shopify_badge.png" alt="Shopify Badge" style={{ width: 24, height: 24 }} />}>
                Checkout
              </Button>

              <Typography variant="caption" display="block">
                <br></br>Secure and trusted checkout with Shopify
              </Typography>
            </Box>
          </Box>
        </Drawer>
      );
    };
    
export default ShoppingCart;
