// BenefitsPage.js
import React from 'react';
import { Container, Box } from '@mui/material';
import Benefits from '../components/Benefits';
import BuyButton from '../components/BuyButton';
import LearnNav from '../components/LearnNav';

function BenefitsPage({ checkout, onCartUpdate }) {
  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Benefits />
        <Box id="buy-button" mt={2}>
        <BuyButton 
            checkout={checkout} 
            onCartUpdate={onCartUpdate} 
            sourcePage="Benefits"
            />
        </Box>
        <LearnNav />
      </Box>
    </Container>
  );
}

export default BenefitsPage;
